$navbar--bg: #000000 !default;

.navbar {
  background: $navbar--bg;
  padding: 5px;
  width: unset !important;

  .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .dropdown-menu {
    left: unset !important;
    top: unset !important;
  }
  .nav-item {
    a {
      color: theme-color("primary-light");
    }
    .dropdown-menu {
      background: $dropdown-bg;
    }
  }
  .btn {
    margin-left: 10px;
    background: theme-color("primary-dark");
    color: #ccc;
    border: 0px;
  }
}