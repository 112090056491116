@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto+Slab:100,300,400,700');

$navbar--bg: #333333;
$dropdown-bg: #555;
$dropdown-link-hover-bg: #333;
$body-bg: #fff;
$body-color: #000;
$footer-color: #ffffff;
$btn-primary-color: $footer-color;
$border-radius-base: 2px;
$gray-light: #999999;
$gray-lighter: #cccccc;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;

$fa-font-path: '../fonts';

// Required
@import "_import";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

$theme-colors: (
  "secondary": $footer-color,
  "primary": #333333,
  "primary-dark": #333,
  "primary-light": #fff,
  "primary-light-hover": #60a900,
  "btn-primary-text-hover": #fff,
  "btn-primary-text": #212529,
  "green": #005218,
  "red": #520007,
  "red-light": #630007,
  "zusage": #005218,
  "zusage-hover": #006319,
  "absage": #520007,
  "absage-hover": #630007,
  "warten": #9f9f00,
  "warten-hover": #8f8f00,
  "danger": #dc3545,
  "success": #60a900
);

//$headings-font-family: 'Calibri', $font-family-sans-serif;
//$font-family-base: 'Calibri', $font-family-sans-serif;
//$headings-font-weight: 700;
$body-bg: #333;

$input-border-color: #000;
$input-focus-box-shadow: #000;
$input-focus-border-color: #ccc;
$input-focus-bg: #555;
$input-focus-color: #fff;

$input-btn-focus-color: #fff;

$input-bg: #333;
$input-color: #ccc;

$table-cell-padding: 0px 5px;

$body-color: #ccc;
//$font-size-base: 1rem;
$modal-content-bg: $body-bg;
$container-max-widths: (
  xl: 1800px
);

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";
@import "navs/topnav";

@import "main";
@import "colorpicker";
@import "jquery.contextmenu";
@import "jquery.datepick";
@import "../node_modules/select2/src/scss/core";
@import "../node_modules/select2-bootstrap4-theme/src/select2-bootstrap4";

@import "chat";

$s2bs-input-bg: $input-bg;
$btn-primary-bg: #41bca0;
.btn-primary {
  background: $btn-primary-bg;
}

.close {
  color: #ffffff;
  opacity: 1;
  text-shadow: 0.5px 0.5px #171717;
}

.modal-header {
  border-bottom: 1px solid #696969;
}

.modal-footer {
  border-top: 1px solid #696969;
}

.btn-secondary {
  background: #bc5940;
  color: #fff;
  border: 0px;
}

.select2-results__option {
  background: $input-focus-bg;
}
.select2-results__option--highlighted {
  background: $input-bg;
}
.select2-search {
  background: $input-focus-bg;
}

.select2-container--bootstrap4 .select2-results__option--highlighted[aria-selected],
.select2-container--bootstrap4 .select2-results__option[aria-selected=true] {
  background: $input-bg;
  color: $input-color;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection, .select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background: $input-bg;
  color: $input-color;
}


.select2-container--bootstrap4 .select2-dropdown {
  border-color: #666;
}

::selection {
  text-shadow: none;
  background: theme-color('primary');
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 63px;

  .sticky-top {
    position: fixed;
    width: 100%;
  }

  header {
    display: flex;
    flex-direction: row;
    background: #333;
    box-shadow: 0px 0px 15px #000;
    position: fixed;
    width: 100%;
    z-index: 900;
    top: 0px;
  }
}

.auftraghead {
  padding: 0px;
  display: flex;
  margin: 0px;
}

.btn-transparent {
  background: none;
  border: 0px;
}

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus,
.btn-transparent:visited,
.btn-transparent:focus-within {
  border: 0px;
  box-shadow: none;
}

.table-auftrag-stamm {
  font-size: 20px;
}

.au {
  .hide {
    height: 0px;
    display: none;
  }
  .showall {
    height: unset !important;
    display: unset;
  }

  .summen {
    font-size: 16px;
    font-weight: bold;
  }
}

.btn-colors {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.daterangepicker {
  background-color:#222;}
.daterangepicker:after, .daterangepicker:before {
  border-bottom-color:rgba(0, 0, 0, .2);
  content:""}
.daterangepicker:before {
  top:-7px;
  border-right:7px solid transparent;
  border-left:7px solid transparent;
  border-bottom:7px solid #888}
.daterangepicker:after {
  top:-6px;
  border-right:6px solid transparent;
  border-bottom:6px solid #222;
  border-left:6px solid transparent}

.daterangepicker.opensright:before {
  left:9px}
.daterangepicker.opensright:after {
  left:10px}
.daterangepicker.dropup {
  margin-top:-5px}
.daterangepicker.dropup:before {
  top:auto;
  bottom:-7px;
  border-bottom:initial;
  border-top:7px solid #888}
.daterangepicker.dropup:after {
  top:auto;
  bottom:-6px;
  border-bottom:initial;
  border-top:6px solid #222}
.daterangepicker.dropdown-menu {
  max-width:none;
  z-index:3001}
.daterangepicker.single .calendar, .daterangepicker.single .ranges {
  float:none}
.daterangepicker.show-calendar .calendar {
  display:block}
.daterangepicker .calendar {
  display:none;
  max-width:270px;
  margin:4px}
.daterangepicker .calendar.single .calendar-table {
  border:none}
.daterangepicker .calendar td, .daterangepicker .calendar th {
  white-space:nowrap;
  text-align:center;
  min-width:32px}
.daterangepicker .calendar-table {
  border:1px solid #222;
  padding:4px;
  background-color:#222}
.daterangepicker table {
  width:100%;
  margin:0}
.daterangepicker td, .daterangepicker th {
  text-align:center;
  border:1px solid transparent;
  white-space:nowrap;
  cursor:pointer}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color:#767676;
  border-color:transparent;
  color:inherit}
.daterangepicker td.week, .daterangepicker th.week {
  font-size:80%;
  color:#ccc}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
  background-color:#222;
  border-color:transparent;
  color:#888}
.daterangepicker td.in-range {
  background-color:#333;
  border-color:transparent;
  color:#fff;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color:#41bca0;
  border-color:transparent;
  color:#222}
.daterangepicker option.disabled, .daterangepicker td.disabled {
  color: #999;
}
.daterangepicker .input-mini {
  border:1px solid #888;
  color:#e5e5e5;
}
.daterangepicker .input-mini.active {
  border:1px solid #888;
  }
.daterangepicker .calendar-time select.disabled {
  color:#888;
}
.ranges li {
  font-size:13px;
  background-color:#767676;
  border:1px solid #888;
  color:#e5e5e5;
}
.ranges li.active, .ranges li:hover {
  background-color:#6e7792;
  border:1px solid #8b92a8;
  color:#222
}
@media (min-width:730px) {
  .daterangepicker .ranges {
    width:auto}
  .daterangepicker.ltr .ranges {
    float:left}
  .daterangepicker.rtl .ranges {
    float:right}
  .daterangepicker .calendar.left {
    clear:none!important}
}

.email-repeat {
  display: none;
  visible: hidden;
  opacity: 0;
}

.xdebug-error {
  th {
    background: #000;
    color: #fff;
  }
  td {
    background: #333;
  }
}

.table-dark {
  color: #bfbebe;
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
}

.footer-alert {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  opacity: 1;
  transform: translateY(0px);
  transition: all 1s;
  transition-delay: 0.5s;
  margin: 0px;
  padding: 0px;
  .alert {
    text-align: center;
  }
}

.hideAlert {
  opacity: 0;
  transform: translateY(50px);
}

body .sticky-top {
  position: sticky !important;
  display: flex;
}

@import "muster";

@import "dispo";

@import "mobile";
