
h1					{ font-size: 18px; font-weight: bold; padding: 0px; margin: 0px; padding-bottom: 15px; }


#logoterminplaner	{ position: fixed; top: 5px; left: 50%; margin-left: -365px; z-index: 99; width: 174px; height: 58px; background: url(../img/logo_head.jpg); }
#boxhead			{ position: fixed; top: 0px; left: 50%; margin-left: -375px; z-index: 98; width: 750px; height: 70px; background: #ffffff; box-shadow: 0px 0px 5px #8a8a8a; border-radius: 0px 0px 5px 5px; }

#mainmenu			{ position: fixed; width: 526px; list-style-type: none; top: 19px; left: 50%; z-index: 9999; margin: 0px; padding: 0px; margin-left: -173px; background: rgb(255,0,0); /* Old browsers */
						background: -moz-linear-gradient(top,  rgba(255,0,0,1) 0%, rgba(153,0,0,1) 100%); /* FF3.6+ */
						background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,0,0,1)), color-stop(100%,rgba(153,0,0,1))); /* Chrome,Safari4+ */
						background: -webkit-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
						background: -o-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* Opera 11.10+ */
						background: -ms-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* IE10+ */
						background: linear-gradient(to bottom,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* W3C */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#990000',GradientType=0 ); /* IE6-9 */
						 border-radius: 3px; }
#mainmenu li		{ position: relative; width: 120px; float: left; font-size: 14px; font-weight: bold; height: 20px; text-align: center; margin-right: 0px; padding: 5px; color: #ffffff; border: 0px; 
					  border-right: 1px;
					  border-style: solid;
					  -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgb(255, 66, 66)), to(rgb(125, 0, 0))) 1 100%;
					  -webkit-border-image: -webkit-linear-gradient(rgb(255,66,66), rgb(125, 0, 0)) 1 100%; 
					  -moz-border-image: -moz-linear-gradient(rgb(255,66,66), rgb(125, 0, 0)) 1 100%; 
					  -o-border-image: -o-linear-gradient(rgb(255,66,66), rgb(125, 0, 0)) 1 100%; 
					  border-image: linear-gradient(to bottom, rgb(255,66,66), rgb(125, 0, 0)) 1 100%; 
					  cursor: pointer; }

#loginwindow		{ position: fixed; width: 300px; height: 220px; left: 50%; top: 50%; margin-left: -150px; margin-top: -100px; background: #ffffff; z-index: 9999999; box-shadow: 0px 0px 5px #000000; border-radius: 10px; }
.loginheadline		{ width: 100%; margin-bottom: 10px; margin-top: 10px; text-align: center;position: relative; }
.logininputdesc		{ width: 50px; text-align: right; padding-right: 5px; font-weight: bold; float: left;  font-size: 14px; margin-top: 5px; }
.logininputval		{ width: 240px; text-align: left; float: left; }
.logininputval input	{ border: 0px; box-shadow: inset 0px 0px 4px 0px #999999; font-family: Calibri; padding: 5px; margin-bottom: 2px; width: 190px; }
#loginbutton		{ position: relative; left: 50%; margin-left: -50px !important; margin-top: 15px; border-radius: 3px; border: 0px;  background: rgb(255,255,255);
					  font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer; }
.loginmessages		{ position: absolute; top: 190px; width: 300px; left: 0px; text-align: center; font-weight: bold; font-size: 18px; color: #990000; }


/**
 * MITARBEITER CSS BEREICH
 **/
#maedit					{ position: fixed; width: 500px; height: 380px; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
.maeditbez				{ position: relative; float: left; min-width: 70px; height: 30px; }
.maeditval				{ position: relative; float: left; min-width: 120px; margin-right: 10px; height: 30px; }
.maeditval input		{ max-width: 150px; }
.maeditfuehrerschein	{ position: relative; padding-top: 15px; clear: both; width: 530px;}
.maeditfuehrerschein .maeditbez { width: 180px; }

#closemaedit			{ position: absolute; top: 0px; right: 0px; width: 22px; height: 22px; background: url(../img/close.png); cursor: pointer; }


.ma						{ position: relative; width: 770px; z-index: 1; margin-top: 30px; margin-left: -385px; left: 50%; text-align: center; }
#ma						{ text-align: left; margin-top: 15px; }
.mitarbeiterlisting		{ position: relative; width: auto; clear:both; }
.mitarbeiterlisting div	{ position: relative; width: auto; margin-right: 10px; float: left; }
.headline				{ border-bottom: 1px solid #999999; margin: 0px !important; padding-right: 10px; font-weight: bold; }
.malisting				{ background: #ffffff; height: 20px; }


	.psid				{ width: 15px !important; }
	.nachname			{ width: 150px !important; }
	.vorname			{ width: 150px !important; }
	.email				{ width: 250px !important; }
	.mobil				{ width: 150px !important; }

	
	
/**
 * GRUPPEN CSS BEREICH
 **/
#groupedit								{ position: fixed; width: 550px; height: auto; top: 50%; left: 50%; margin-top: -200px; margin-left: -275px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; padding-bottom: 10px; }
.groupeditbez							{ position: relative; float: left; min-width: 70px; height: 30px; }
.groupeditval							{ position: relative; float: left; min-width: 120px; margin-right: 10px; height: 30px; }
.groupeditfuehrerschein					{ position: relative; padding-top: 15px; clear: both; width: 530px;}
.groupeditfuehrerschein .groupeditbez	{ width: 180px; }

#closegroupedit							{ position: absolute; top: 0px; right: 0px; width: 22px; height: 22px; background: url(../img/close.png); cursor: pointer; }


.group									{ position: relative; width: 300px; z-index: 1; margin-top: 30px; margin-left: -150px; left: 50%; text-align: center;}
#group									{ text-align: left; margin-top: 15px; }
.grouplisting							{ position: relative; width: auto; clear:both; }
.grouplisting div						{ position: relative; width: auto; margin-right: 10px; float: left; }
.grouplisting							{ background: #ffffff; height: 20px; }

.grouplisting .bezeichnung						{ width: 250px !important; }
	
	
	
/**
 * AUFTRAGSERFASSUNG CSS BEREICH
 **/
 
 .selector						{ position: relative; width: 120px; left: 50%; margin-left: -60px; margin-top: 10px; }
 
 .addAuftrag					{ position: relative; left: 50%; margin-left: -70px !important; margin-top: 30px; border-radius: 3px; border: 0px; background: rgb(255,255,255); /* Old browsers */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(204,204,204,1) 76%, rgba(153,153,153,1) 100%); /* FF3.6+ */
									background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(76%,rgba(204,204,204,1)), color-stop(100%,rgba(153,153,153,1))); /* Chrome,Safari4+ */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Chrome10+,Safari5.1+ */
									background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Opera 11.10+ */
									background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* IE10+ */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* W3C */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
									font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer; }
.addAuftrag:hover				{ color: #990000; }
 .auftragserfassung				{ position: fixed; width: 500px; height: auto; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
 .windowcontent					{ padding: 10px; }
 .windowcontent div				{ position: relative; float: left; margin-bottom: 5px; font-size: 14px; }
  	.aueditbez					{ width: 150px; }
 	.aueditval					{ width: 250px; }
 	.aueditval input			{ width: 250px; }
 .auftragserfassung .button		{ border: 0px; width: 100px; box-shadow: 0px 0px 5px #999999; height: 30px; border-radius: 3px; border: 0px; background: rgb(255,255,255); /* Old browsers */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(204,204,204,1) 76%, rgba(153,153,153,1) 100%); /* FF3.6+ */
									background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(76%,rgba(204,204,204,1)), color-stop(100%,rgba(153,153,153,1))); /* Chrome,Safari4+ */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Chrome10+,Safari5.1+ */
									background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Opera 11.10+ */
									background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* IE10+ */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* W3C */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
									font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer;
									left: 50%; position: absolute; margin-left: -60px; bottom: 10px; z-index: 99;
								}
.auftragserfassung .button:hover				{ color: #990000; }

 
 .auftragserfassungFileUpload	{ position: fixed; width: 450px; min-height: 380px; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
 .dateien						{ position: relative; margin-top: 10px; }
 .datei							{ position: relative; width: 430px; height: 60px; }
 	.progressbar					{ position: relative; clear: both; width: 370px; height: 19px; float: left; background: url(../img/loadingBarGrey.png); }
 	.bar							{ position: relative; height: 19px; background: url(../img/loadingBar.png); }
 	.percent						{ position: relative; height: 21px; floaat: left; padding-right: 20px; margin-left: 5px; }
 	.file							{ position: relative; width: 430px; float: left; }
 
 .auftragserfassungPos			{ position: fixed; width: 500px; height: auto; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
 #speicherAuPo					{margin-left: 190px; }
 .auftragserfassungPos .button	{ border: 0px; width: 100px; box-shadow: 0px 0px 5px #999999;  border-radius: 3px; border: 0px; background: rgb(255,255,255); /* Old browsers */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(204,204,204,1) 76%, rgba(153,153,153,1) 100%); /* FF3.6+ */
									background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(76%,rgba(204,204,204,1)), color-stop(100%,rgba(153,153,153,1))); /* Chrome,Safari4+ */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Chrome10+,Safari5.1+ */
									background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Opera 11.10+ */
									background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* IE10+ */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* W3C */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
									font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer; }
.auftragserfassungPos .button:hover				{ color: #990000; }

#fileInput	{ border: 0px; width: 108px; box-shadow: 0px 0px 5px #999999;  border-radius: 3px; border: 0px; background: rgb(255,255,255); font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer; }
#fileInput:hover				{ color: #990000; }
 
 .auftragSendInfo				{ position: fixed; width: 900px; min-height: 580px; top: 50%; left: 50%; margin-top: -290px; margin-left: -450px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; border-radius: 5px; display: none; }
 	.infoeditbez				{ position: relative; width: 200px; float: left; }
 	.infoeditval				{ position: relative; width: 400px; float: left; }
 	#gebuchtespersonal			{ position: relative; width: 430px; height: 165px; overflow-y: scroll; }
 	#dateianhaenge				{ position: relative; width: 430px; height: 165px; overflow-y: scroll; }
 
 .auftragfiles					{ position: relative; width: 100%; height: 16px; overflow: hidden; border: 1px solid #999999; border-top: 0px; border-bottom: 0px; padding: 4px; }
 
 .auftraege						{ position: relative; width: 98%; margin-left: 2%; }
		.showFiles				{ position: relative; float: left; width: 15px; height: 15px; background: url(../img/showfiles_icon_black.png); cursor: pointer; margin-top: 0px; margin-left: 2px; }
		.addFiles				{ position: relative; float: left; width: 18px; height: 18px; margin-top: 1px; background: url(../img/addfile_icon.png); cursor: pointer; margin-left: 5px; }
		.files					{ position: relative; width: 100%; }	 		
	 	.auFile					{ position: relative; width: 100%; max-width: 700px; height: 20px; padding-left: 5px; padding-top: 5px; padding-bottom: 5px; }
	 	.auFile div				{ position: relative; float: left; }
	 		.auFileIcon			{ width: 30px; height: 20px; }
	 		.auFileName			{ width: 50%; margin-top: 2px; padding-right: 15px; overflow: hidden; }
	 		.auFileUploader		{ padding-right: 15px; min-width: 150px; margin-top: 2px; }
	 		.auFileUploadTime	{ margin-top: 2px; }
	 		.auFileDelete		{ margin-left: 10px; width: 15px; margin-top: 2px; height: 15px; background: url(../img/delete_icon_black.png); cursor: pointer; }
	 		
	 	.auftragpositionen			{ position: relative; width: 100%; border: 1px solid #999999; padding: 4px; border-radius: 0px 0px 5px 5px; }
	 	.editAuPo					{ position: relative; float: left; margin-right: 5px; cursor: pointer; width: 15px; height: 15px; background: url('../img/edit_icon_black.png'); margin-top: 1px; }
 		.delAuPo					{ position: relative; float: left; width: 15px; height: 15px; margin-top: 1px; background: url(../img/delete_icon_black.png); cursor: pointer; margin-left: 5px; }
	 		.tableheadcontent		{ position: relative; float: left; height: 15px; font-size: 11px; font-weight: normal; border-bottom: 1px solid #cc9999; }
	 		.tablecontent			{ position: relative; float: left; height: 17px; }
	 		.apid					{ width:  5%; min-width:  50px; }
	 		.apbezeichnung			{ width: 20%; min-width: 150px; }
	 		.apzeit					{ width: 20%; min-width: 150px; }
	 		.appersonal				{ width:  5%; min-width: 80px; }
	 		.aport					{ width: 20%; min-width: 200px; }
	 		.aparbeitszeit			{ width: 5%; min-width: 70px; }
	 		.aparbeitszeitgesamt	{ width: 10%; min-width: 100px; }
	 		.apfunctions			{ width:  8%; min-width: 80px; }
	 		
	 		
/**
 * DISPOSITION CSS BEREICH
 */
 
 .disposition					{ position: fixed; width: 500px; height: 380px; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0px 0px 5px #999999; background: #ffffff; z-index: 99; padding: 0px; display: none; }
 .windowcontent					{ padding: 10px; }
 .windowcontent div				{ position: relative; float: left; margin-bottom: 5px; font-size: 14px; }
  	.dispoeditbez				{ width: 150px; }
 	.dispoeditval				{ width: 250px; }
 	.dispoeditval input			{ width: 250px; }
 .disposition .button			{ border: 0px; width: 100px; box-shadow: 0px 0px 5px #999999; height: 30px; }
 
 .dispositionFHZChange			{ position: fixed; width: 500px; height: auto; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
 .dispositionFHZChange .fhzval	{ position: relative; width: 150px; height: 18px; float: left; }
 .dispositionFHZChange .fhzbez	{ position: relative; width: 200px; height: 18px; float: left; }
 #changeFhzPo					{ position: relative; clear: both; width: 100px; margin-left: -150px; margin-top: 70px; }
 
 #addDispoPersonal					{ position: relative; left: 50%; width: 100px; margin-left: -50px; }
 
 .dispositionMitarbeiterEdit2		{ position: fixed; width: 900px; height: auto; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: block; border-radius: 5px; }
 .dispositionMitarbeiterEdit2 .dispbez	{ position: relative; width: 150px; height: 18px; float: left; }
 .dispositionMitarbeiterEdit2 .dispval	{ position: relative; width: 300px; height: 18px; float: left; }
  
 
 .dispositionauftragserfassungPos			{ position: fixed; width: 500px; height: 380px; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0px 0px 5px #999999; background: #ffffff; z-index: 99; padding: 0px; display: none; }
 .dispositionauftragserfassungPos .button	{ border: 0px; width: 100px; box-shadow: 0px 0px 5px #999999; height: 30px; }

 .dispomenue2						{ height: 10px; top: 62px; width: 750px; margin-left: -375px; position: fixed; left: 50%; z-index: 999; background: #ffffff; }
 
 .dispoauftraege					{ position: relative; width: 100%; min-width: 1024px; margin-top: 0px; margin-bottom: 30px; }
 		.dispoauftraghead			{ position: relative; width: 100%;
										padding: 0px 5px 0px 5px; color: #ffffff; border-radius: 5px 5px 0px 0px; }


		.row						{ position: relative; }
	 	.editMA						{ position: relative; float: left; margin-right: 5px; cursor: pointer; }
 		.delAuPo					{ position: relative; float: left; }
	 		.tableheadcontent		{ position: relative; float: left; height: 15px; font-size: 11px; font-weight: normal; border-bottom: 1px solid #dadada; margin: 5px 0px 5px 0px; }
	 		.tablecontent			{ position: relative; float: left; height: 17px; }
	 		.dispokz				{ width: 30px;}


.hoverPersonal						{ background: #cecece !important; }
			
			
/**
 * SMS CHAT STYLING
 **/

 	#smschat				{ position: fixed; bottom: 0px; right: 0px; z-index: 999; width: 200px; max-height: 300px; background: #ffffff; box-shadow: 0px 0px 5px #666666; border-radius: 5px 5px 0px 0px; }
 	#smschat .smsmenue		{ position: relative; width: 100%; min-height: 20px; height: 20px; }
 	.smsmenuehead			{ position: relative; width: 100%; border-radius: 5px 5px 0px 0px; padding-left: 5px; font-size: 16px; font-weight: bold; color: #ffffff; text-align: center; height: 25px; 
 								 background: rgb(255,0,0); /* Old browsers */
								background: -moz-linear-gradient(top,  rgba(255,0,0,1) 0%, rgba(153,0,0,1) 100%); /* FF3.6+ */
								background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,0,0,1)), color-stop(100%,rgba(153,0,0,1))); /* Chrome,Safari4+ */
								background: -webkit-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
								background: -o-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* Opera 11.10+ */
								background: -ms-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* IE10+ */
								background: linear-gradient(to bottom,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* W3C */
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#990000',GradientType=0 ); /* IE6-9 */
								  border-radius: 5px 5px 0px 0px; cursor: pointer;
							}
 	.smsmenuecontent		{ position: relative; width: 100%; height: 300px; overflow-y: scroll; }
 	.smsmenuecontent ul		{ position: relative; list-style-type: none; width: 100%; font-size: 14px; text-align: left; margin: 0px; padding: 0px; margin-left: 5px; height: auto; padding-bottom: 30px; }
 	.smsmenuecontent ul li	{ position: relative; width: 100%; padding: 5px; cursor: pointer; }
 			.smsmouseover	{ background: #999999; }
 	.sendbtn				{ width: 30px; top: -9px; position: relative; }
 	
 	#smsfenster				{ position: fixed; bottom: 0px; right: 200px; z-index: 999; }
 	.chatfenster			{ position: relative; width: 250px; height: auto; max-height: 360px; background: #ffffff; border-radius: 5px 5px 0px 0px; float: right;  box-shadow: 0px 0px 5px #999999; margin-right: 10px; }
 	.chatfenster_close		{ position: absolute; height: 22px; width: 22px; background: url(../img/close.png); right: 0px; z-index: 999; cursor: pointer}
 	.chatfenster_title		{ position: relative; width: 247px; height: 30px; font-size: 16px; font-weight: bold; border-radius: 5px 5px 0px 0px; padding-left: 5px;  height: 25px; 
 								 background: rgb(255,0,0); /* Old browsers */
								background: -moz-linear-gradient(top,  rgba(255,0,0,1) 0%, rgba(153,0,0,1) 100%); /* FF3.6+ */
								background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,0,0,1)), color-stop(100%,rgba(153,0,0,1))); /* Chrome,Safari4+ */
								background: -webkit-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
								background: -o-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* Opera 11.10+ */
								background: -ms-linear-gradient(top,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* IE10+ */
								background: linear-gradient(to bottom,  rgba(255,0,0,1) 0%,rgba(153,0,0,1) 100%); /* W3C */
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#990000',GradientType=0 ); /* IE6-9 */
								  border-radius: 5px 5px 0px 0px; cursor: pointer; color: #ffffff; }
 	.chatfenster_messages	{ position: relative; width: 100%; height: auto; background: #ffffff; overflow: hidden; overflow-y: scroll; max-height: 270px; }
 	.chatfenster_newmsg		{ position: relative; width: 100%; height: auto; min-height: 20px; z-index: 999; }
 	.chatfenster_newmsg textarea	{ position: relative; width: 202px; height: auto; min-height: 20px; resize: none; font-family: Calibri; font-size: 13px; }
 	
 	.chat_person			{ position: relative; width: 75%; float: right; background: #990000; border-radius: 5px; padding: 5px; color: #000000; margin-right: 10px; margin-top: 10px; margin-bottom: 10px; }
 	.chatmsg				{ color: #ffffff; }
 	.chat_own				{ position: relative; width: 75%; float: left;  background: #e5e4ec; border-radius: 5px; padding: 5px; color: #000000; margin-left:  10px; margin-top: 10px; margin-bottom: 10px; }
 	.chat_own .chatmsg		{ color: #000000; }
 	.chattime				{ position: relative; width: 100%; text-align: right; font-size: 10px; color: #999999; }
 	.chat_person .chattime	{ color: #ffffff; }
 	


/**
 * FAHRZEUGVERWALTUNG CSS BEREICH
 **/
#fhzedit				{ position: fixed; width: 500px; height: 380px; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
.fhzeditbez				{ position: relative; float: left; min-width: 70px; height: 30px; }
.fhzeditval				{ position: relative; float: left; min-width: 120px; margin-right: 10px; height: 30px; }
.fhzeditval input,
.fhzeditval select		{ max-width: 150px; }
.fhzeditfuehrerschein	{ position: relative; padding-top: 15px; clear: both; width: 530px;}
.fhzeditfuehrerschein .fhzeditbez { width: 180px; }

#closefhzedit			{ position: absolute; top: 0px; right: 0px; width: 22px; height: 22px; background: url(../img/close.png); cursor: pointer; }


.fhz					{ position: relative; width: 605px; z-index: 1; margin-top: 30px; margin-left: -302px; left: 50%; text-align: center; }
#fhz					{ text-align: left; margin-top: 15px; }
.fahrzeugelisting		{ position: relative; width: auto; clear:both; }
.fahrzeugelisting div	{ position: relative; width: auto; margin-right: 10px; float: left; }
.headline				{ border-bottom: 1px solid #999999; margin: 0px !important; padding-right: 10px; font-weight: bold; }
.fhzlisting				{ background: #ffffff; height: 20px; }


	.psid				{ width: 15px !important; }
	.nachname			{ width: 150px !important; }
	.vorname			{ width: 150px !important; }
	.email				{ width: 250px !important; }
	.mobil				{ width: 150px !important; }

#pa						{ margin-top: 15px; }
	
/**
 * TAETIGKEITEN CSS BEREICH
 **/
#paedit					{ position: fixed; width: 500px; height: 380px; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: none; border-radius: 5px; }
.paeditbez				{ position: relative; float: left; min-width: 70px; height: 30px; }
.paeditval				{ position: relative; float: left; min-width: 120px; margin-right: 10px; height: 30px; }
.paeditfuehrerschein	{ position: relative; padding-top: 15px; clear: both; width: 530px;}
.paeditfuehrerschein .paeditbez { width: 180px; }

#closepaedit			{ position: absolute; top: 0px; right: 0px; width: 22px; height: 22px; background: url(../img/close.png); cursor: pointer; }


.pa						{ position: relative; width: 185px; z-index: 1; margin-top: 30px; margin-left: -92px; left: 50%; }
.fahrzeugelisting		{ position: relative; width: auto; clear:both; }
.fahrzeugelisting div	{ position: relative; width: auto; margin-right: 10px; float: left; }
.headline				{ border-bottom: 1px solid #999999; margin: 0px !important; padding-right: 10px; font-weight: bold; }
.palisting				{ background: #ffffff; height: 20px; }


	.psid				{ width: 15px !important; }
	.nachname			{ width: 150px !important; }
	.vorname			{ width: 150px !important; }
	.email				{ width: 250px !important; }
	.mobil				{ width: 150px !important; }
	
	
#addma					{ position: relative;  margin-left: 10px; border-radius: 3px; border: 0px; background: rgb(255,255,255); /* Old browsers */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(204,204,204,1) 76%, rgba(153,153,153,1) 100%); /* FF3.6+ */
									background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(76%,rgba(204,204,204,1)), color-stop(100%,rgba(153,153,153,1))); /* Chrome,Safari4+ */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Chrome10+,Safari5.1+ */
									background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Opera 11.10+ */
									background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* IE10+ */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* W3C */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
									font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer; }
#addma:hover			{ color: #990000; }


input[type="button"] { border-radius: 3px; border: 0px; background: rgb(255,255,255); /* Old browsers */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(204,204,204,1) 76%, rgba(153,153,153,1) 100%); /* FF3.6+ */
									background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(76%,rgba(204,204,204,1)), color-stop(100%,rgba(153,153,153,1))); /* Chrome,Safari4+ */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Chrome10+,Safari5.1+ */
									background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Opera 11.10+ */
									background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* IE10+ */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* W3C */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
									font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer; margin-left: 5px; }
input[type="button"]:hover			{ color: #990000; }



.dateformat			{ width: 80px !important; }










/**
 * AUFTRAGSERFASSUNG CSS BEREICH
 **/
 #addZeit						{ position: relative; }
 .zeiten						{ position: relative; width: 96%; margin-left: 2%; top: 60px; }
 	.auftragslisting			{ position: relative; width: 95%; margin-top: 10px; min-width: 970px; }
 		.zthead					{ position: relative; width: 100%; height: 30px; background: #cc9999; padding: 0px 5px 0px 5px; color: #ffffff; border-radius: 5px 5px 0px 0px; font-size: 20px; font-weight: bold;  }
	 	.ztpositionen			{ position: relative; width: 100%; border: 1px solid #999999; padding: 4px; border-radius: 0px 0px 5px 5px; min-width: 1200px; }
	 		.tableheadcontent		{ position: relative; float: left; height: 15px; font-size: 11px; font-weight: normal; border-bottom: 1px solid #cc9999; }
	 		.tablecontent			{ position: relative; float: left; height: 17px; }
	 		.ztdatum				{ width:  5%; min-width:  50px; }
	 		.ztvon					{ width:  5%; min-width:  50px; }
	 		.ztbis					{ width:  5%; min-width:  50px; }
	 		.zttaetigkeit			{ width: 45%; min-width:  50px; }
	 		.ztlohn					{ width:  5%; min-width:  50px; }
	 		.ztstd					{ width: 10%; min-width:  50px; text-align: right; }
	 		.ztpause				{ width: 10%; min-width:  50px; text-align: right; }
	 		.ztgstlohn				{ width: 10%; min-width: 100px; text-align: right; }
	 		.ztgesamt				{ width: calc(75% - 5px); min-width: 150px; text-align: right; font-weight: bold; padding-right: 5px; }
	 		
	 		.ztstunden				{ width: 150px; float: left; }
	 		.ztwerte				{ width: auto; float: left; }
	 		
 .zeiterfassung				{ position: fixed; width: 500px; height: auto; top: 50%; left: 50%; margin-top: -200px; margin-left: -250px; box-shadow: 0 0 5px #8A8A8A; background: #ffffff; z-index: 9999; padding: 0px; display: block; border-radius: 5px; }
 .windowcontent					{ padding: 10px; }
 .windowcontent div				{ position: relative; float: left; margin-bottom: 5px; font-size: 14px; }
  	.aueditbez					{ width: 150px; }
 	.aueditval					{ width: 250px; }
 	.aueditval input			{ width: 250px; }
 .zeiterfassung .button		{ border: 0px; width: 100px; box-shadow: 0px 0px 5px #999999; height: 30px; border-radius: 3px; border: 0px; background: rgb(255,255,255); /* Old browsers */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(204,204,204,1) 76%, rgba(153,153,153,1) 100%); /* FF3.6+ */
									background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(76%,rgba(204,204,204,1)), color-stop(100%,rgba(153,153,153,1))); /* Chrome,Safari4+ */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Chrome10+,Safari5.1+ */
									background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* Opera 11.10+ */
									background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* IE10+ */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(204,204,204,1) 76%,rgba(153,153,153,1) 100%); /* W3C */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
									font-weight: bold; height: 30px; box-shadow: 0px 0px 3px #000000; cursor: pointer;
									left: 50%; position: absolute; margin-left: -60px; bottom: 10px; z-index: 99;
								}
.zeiterfassung .button:hover				{ color: #990000; }


.ztlmitarbeiter			{ position: relative; width: 120px; }
.ztlmonat				{ position: relative; text-align: center; width: calc(100% / 13 - 15px) }
.ztlmonat2				{ background: #dedede; }



#hamburger				{ position: fixed; left: 10px; top: 10px; width: 30px; height: 15px; border-top: 4px solid #4e5b02; border-bottom: 4px solid #4e5b02; z-index: 99999; display: none; }
#hamburger .mobilmenue	{ position: relative; width: 100%; height: 100%; }
#hamburger .devider		{ position: relative; top: calc(50% - 2px); width: 100%; height: 4px; background: #4e5b02; }

.tablecell	{ display: table-cell; float: none !important; padding: 0px 0px 0px 5px; }
.tablerow	{ display: table-row; clear: both !important; }
