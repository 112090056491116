.btn-muster {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.microbialmat {
  background:
          radial-gradient(circle at 0% 50%, rgba(255, 255, 255, 0) 9px, #333 10px, rgba(255, 255, 255, 0) 11px) 0px 10px,
          radial-gradient(at 100% 100%,      rgba(255, 255, 255, 0) 9px, #333 10px, rgba(255, 255, 255, 0) 11px);
  background-size: 20px 20px;
}

.stairs {
  background:
          linear-gradient(63deg, #333 23%, transparent 23%) 7px 0,
          linear-gradient(63deg, transparent 74%, #333 78%),
          linear-gradient(63deg, transparent 34%, #333 38%, #333 58%, transparent 62%),
          #444;
  background-size: 16px 48px;
}

.half-rombes {
  background:
          linear-gradient(115deg, transparent 75%, rgba(100,100,100,.5) 75%) 0 0,
          linear-gradient(245deg, transparent 75%, rgba(100,100,100,.5) 75%) 0 0,
          linear-gradient(115deg, transparent 75%, rgba(100,100,100,.5) 75%) 7px -15px,
          linear-gradient(245deg, transparent 75%, rgba(100,100,100,.5) 75%) 7px -15px,
          #000;
  background-size: 15px 30px;
}

.arrows {
  background-color:#269;
  background-image: linear-gradient(white 2px, transparent 2px),
  linear-gradient(90deg, rgba(150,150,150,1) 1px, transparent 2px),
  linear-gradient(rgba(150,150,150,1) 1px, transparent 1px),
  linear-gradient(90deg, rgba(150,150,150,1) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

.checkboard {
  background-color: #eee;
  background-image: linear-gradient(45deg, #555 25%, transparent 25%, transparent 75%, #555 75%, #555),
  linear-gradient(45deg, #555 25%, transparent 25%, transparent 75%, #555 75%, #555);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.bricks {
  background-color: silver;
  background-image: linear-gradient(335deg, #b00 23px, transparent 23px),
  linear-gradient(155deg, #d00 23px, transparent 23px),
  linear-gradient(335deg, #b00 23px, transparent 23px),
  linear-gradient(155deg, #d00 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
}

.honeycomb {
  background:linear-gradient(324deg, #232927 4%,   transparent 4%) -70px 43px,
          linear-gradient( 36deg, #232927 4%,   transparent 4%) 30px 43px,
          linear-gradient( 72deg, #000000 8.5%, transparent 8.5%) 30px 43px,
          linear-gradient(288deg, #000000 8.5%, transparent 8.5%) -70px 43px,
          linear-gradient(216deg, #000000 7.5%, transparent 7.5%) -70px 23px,
          linear-gradient(144deg, #000000 7.5%, transparent 7.5%) 30px 23px,

          linear-gradient(324deg, #232927 4%,   transparent 4%) -20px 93px,
          linear-gradient( 36deg, #000000 4%,   transparent 4%) 80px 93px,
          linear-gradient( 72deg, #000000 8.5%, transparent 8.5%) 80px 93px,
          linear-gradient(288deg, #000000 8.5%, transparent 8.5%) -20px 93px,
          linear-gradient(216deg, #000000 7.5%, transparent 7.5%) -20px 73px,
          linear-gradient(144deg, #000000 7.5%, transparent 7.5%) 80px 73px;
  background-color: #232927;
  background-size: 100px 100px;
}

.cross {
  background-color: gray;
  background-image: linear-gradient(transparent 50%, rgba(0,0,0,.5) 50%);
  background-size: 5px 5px;
}

.crossvert {
  background-color: gray;
  background-image: linear-gradient(90deg, transparent 50%, rgba(0,0,0,.5) 50%);
  background-size: 10px 10px;
}

.crossdia {
  background-color: gray;
  background-image: linear-gradient(45deg, transparent 50%, rgba(0,0,0,.5) 50%);
  background-size: 90px 90px;
}

.upholstery {
  background: radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 9%, hsla(0, 100%, 20%, 0) 9%) 0 0,
          radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 8%, hsla(0, 100%, 20%, 0) 10%) 50px 50px,
          radial-gradient(hsla(0, 100%, 30%, 0) 20%, hsla(0, 100%, 20%, 0)) 50px 0,
          radial-gradient(hsla(0, 100%, 30%, 0) 20%, hsla(0, 100%, 20%, 0)) 0 50px,
          radial-gradient(hsla(0, 100%, 20%, 0) 35%, hsla(0, 100%, 20%, 0) 60%) 50px 0,
          radial-gradient(hsla(0, 100%, 20%, 0) 35%, hsla(0, 100%, 20%, 0) 60%) 100px 50px,
          radial-gradient(hsla(0, 100%, 15%, 0), hsla(0, 100%, 20%, 0)) 0 0,
          radial-gradient(hsla(0, 100%, 15%, 0), hsla(0, 100%, 20%, 0)) 50px 50px,
          linear-gradient(45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0,
          linear-gradient(-45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0;
  background-size: 50px 50px;
}