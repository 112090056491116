.dispomenue {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: theme-color("primary-light");
  font-size: 20px;
  #prevyear, #prevmonth, #nextmonth, #nextyear {
    width: 40px;
    padding: 10px;
  }

  #showall {
    padding: 10px;
  }

  #momentshow {
    flex-grow: 5;
    text-align: center;
    padding: 10px;
  }

}

.dispoPosbezeichnung {
  font-size: 20px;
}

.dispoPoszeitraum, .dispoPosort, .dispoPospersonal {
  font-size: 14px;
}

.dispodate {
  background: $input-bg;
  color: $input-color;
  border: $input-border-color;
}

.todayscroll {
  background: linear-gradient(to bottom, #787f00 0%, #136a00 100%) !important;
}

.datumdevider {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  border-top: 1px solid #fff;
  min-height: 60px;
  padding-bottom: 5px;
  background: rgb(31,31,31);
  background: -moz-linear-gradient(top, rgba(31,31,31,1) 0%, rgba(0,53,94,1) 100%);
  background: -webkit-linear-gradient(top, rgba(31,31,31,1) 0%,rgba(0,53,94,1) 100%);
  background: linear-gradient(to bottom, rgba(31,31,31,1) 0%,rgba(0,53,94,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1f1f', endColorstr='#00355e',GradientType=0 );
  overflow: visible;
  padding: 10px 0px;
  min-height: 290px;

  .datum {
    position: relative;
    top: 0px;
    overflow: visible;
    .datumtag {
      display: flex;
      flex-direction: row !important;
      background: #999;
      margin-top: -10px;
      position: sticky;
      top: 50px;
      .day {
        font-size: 70px;
        padding-left: 5px;
        color: #ffffff;
        position: sticky;
        z-index: 1;
        top: 0px;
        opacity: 1;
        text-shadow: 1px 1px #fff;
      }

      .month {
        font-size: 20px;
        color: #aaaaaa;
        margin-left: 5px;
      }

      .weekday {
        font-size: 20px;
        color: #000000;
        margin-left: 5px;
      }

      .weekend {
        background: #8c8c8c;
      }

      .addPos {
        width: 37px;
        height: 63px;
        left: 4px;
        top: 0px;
        z-index: 1000;
        cursor: pointer;
      }

      div {
        position: relative;
        font-size: 20px;
        color: #000;
      }
    }
    display: flex;
    min-height: 50px;
    flex-direction: column;
    width: 150px;
    margin-right: 10px;
  }

  .dispoTermine {
    z-index: 2;
    opacity: 0.8;
    position: sticky;
    top: 155px;
    div {
      position: relative; width: 100%; color: #fff; padding: 3px; border-radius: 2px; margin-bottom: 2px;
    }
    .urlaub			{ background: #990000; }
    .urlaubgenehmigtAlle { background: theme-color("green") !important; }
    .urlaubgenehmigtChef,
    .urlaubGenehmigtDispo { background: #fffa0c !important; color: #000000 !important; }
    .wiederholung		{ background: #ff8000; }
    .otherdate		{ background: #400000; }
  }

  .TagesPositionen {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    z-index: 2;
    opacity: 0.7;
    .dispoauftragslisting	{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: unset;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 10px 0px 0px 0px;
      .dispoauftraghead {
        padding: 0px;
        display: flex;
        margin: 0px;
        border-radius: 10px 0px 0px 0px;
        div {
          display: flex;
          vertical-align: center;
          align-items: center;
        }
      }

      .dispofahrzeuge {
        background: #333;
        .dispFahrzeugListe {
          display: flex;
          flex-direction: row;
        }
      }

      .absage {
        background: theme-color("absage");
      }

      .absage:hover {
        background: theme-color("absage-hover");
      }

      .zusage {
        background: theme-color('zusage');
      }
      .zusage:hover {
        background: theme-color('zusage-hover');
      }

      .aufertigpos {
        background: theme-color("green");
        color: #fff;
        th {
          border-color: #666;
          border-top: 0px;
        }
        td {
          border-color: #666;
        }
      }
      .dispomitarbeiter {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .row {
          margin: 0px;
        }
        .angedacht {
          background: #999;
        }
        .absage {
          background: #ff0000;
          color: #eee;
        }
        .warte {
          background: #ffc000;
          color: #333;
        }
        .warte:hover {
          background: rgba(255,192,0, 0.7);
          color: #000;
        }
        .zusage {
          background: #00ff24;
          color: #333;
        }
        .zusage:hover {
          background: rgba(0, 255, 36, 0.7);
          color: #000;
        }
      }
      .dispofahrzeuge, .dispomitarbeiter {
        transition: all 0.3s;
      }
      .dispomitarbeiter {
        table {
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
      }
      .dispofahrzeuge {
        height: 55px;
        color: theme-color('primary-light');
        padding: 5px;
        display: flex;
        margin: 0px;
      }
      .dispofunctionsPos {
        display: flex;
        position: relative;
        div {
          margin: 0px 5px;
        }
      }
    }

    .hideready {
      .dispofahrzeuge, .dispomitarbeiter {
        height: 0px;
        overflow: hidden;
        padding: 0px;
      }
    }
    .showall {
      .dispofahrzeuge, .dispomitarbeiter {
        height: unset;
        overflow: unset;
        padding: unset;
      }
    }
  }
}

.weekend {
  background: #a57575;
  .datumtag {
    background: #333 !important;
    .day {
      color: #ff0a0a !important;
    }
    .weekday {
      color: #fff !important;
    }
  }
}

.dispositionFahrzeug {
  position: fixed;
  width: 500px;
  height: auto;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  box-shadow: 0 0 5px #8A8A8A;
  background: #ffffff;
  z-index: 9999;
  padding: 0px;
  display: none;
  border-radius: 5px;
}
.dispositionMitarbeiterEdit		{
  position: fixed;
  width: 500px;
  height: auto;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  box-shadow: 0 0 5px #8A8A8A;
  background: #ffffff;
  z-index: 9999;
  padding: 0px;
  display: none;
  border-radius: 5px;
}

