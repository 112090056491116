@include media-breakpoint-down(sm) {
  .kalendermenuepunkt {
    display: none;
  }
  body {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  header {
    bottom: 0px;
    top: unset !important;
    display: flex;
    flex-direction: row-reverse !important;

    .navbar {
      top: unset;
      flex-grow: 1;
      position: relative;

      .navbar-toggler {
        width:40px;
        height:30px;
        padding: 5px;

        div {
          width: 100%;
          height: 1px;
          background: #ffffff;
          margin: 5px;
        }
      }
      .navbar-collapse {
        position: fixed;
        bottom: 82px;
        right: 0px;
        width: 100%;

        .navbar-nav {
          flex-direction: column;
          background: $navbar--bg;
          font-size: 1rem;
          .nav-item {
            width: calc(100%);
            padding: 20px;
            text-align: right;

            a {
              font-size: 1rem;
            }
            .dropdown-menu {
              text-align: right;
            }
          }
        }
      }
    }
    .dispomenue {
      width: unset !important;
      flex-grow: 2;

      #momentshow {
        width: 200px !important;
      }
    }
    #showall {
      display: none;
    }
    #today {
      display: unset;
      width: 80px;
      position: relative;
      background: #666;
    }
  }
  .maincontent {
    margin-top: 0px;
    padding-top: 0px;

    .dispofahrzeuge {
      min-height: 55px;
      //height: unset !important;
    }

    .datumdevider {
      flex-direction: column;
      .TagesPositionen {
        margin-left: 30px;
        padding-top: 5px;
      }
      .datum {
        width: 100%;
        flex-direction: row;
        min-height: unset;
        .datumtag {
          width: 90px;
          min-width: 90px;
        }
        .dispoTermine {
          width: 100%;
          flex-direction: row;
          display: flex;
          opacity: 0.3;
          div {
            white-space: nowrap;
          }
        }
      }
    }

    .hideready {
      .dispoauftraghead {
        height: 30px !important;
        overflow: hidden;
      }
      .dispofahrzeuge {
        min-height: unset;
        height: 0px !important;
        overflow: hidden !important;
      }
      .dispomitarbeiter {
        height: 0px !important;
        overflow: hidden !important;
      }
    }
    .showall {
      .dispoauftraghead {
        height: unset;
      }
    }
  }

  #dispodate {
    width: 100%;
  }

  .chat-fixed-bottom {
    bottom: 80px;
    z-index: 9;
  }

  .datumdevider .datum .datumtag .day {
    font-size: 20px;
    text-shadow: none;
  }
}