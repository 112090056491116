.chat-fixed-bottom {
  pointer-events: none;
}
.chat-wrapper {
  width: 300px;
  pointer-events: all;
  > button {
    box-shadow: 0 0 10px #000;
    z-index: 1;

  }
}
#chatList {
  background-color: #333;
  box-shadow: 0 0 10px #000;
  max-height: 500px;
  overflow-y: auto;
  > div {
    cursor: pointer;
  }
}
.chat-windows {
  pointer-events: all;
  flex-wrap: wrap;
  .chat-window {
    width: 300px;
    max-height: 400px;
    .card {
      background: #999;
    }
    .card-body {
      background: #797979;
      max-height: 247px;
      .chat-own {
        background: #207fea;
        color: #ffffff;
      }
      .chat-person {
        background: #b3b3b3;
        color: #000;
        margin-left: 0px !important;
      }
      .chatmessage {
        padding: 10px;
        border-radius: 10px;
      }
    }
    .card-header,
    .card-footer {
      background: #5f5f5f;
      a {
        color: #eee;
      }
      textarea {
        width: 100% !important;
        resize: none;
      }
    }
  }
  .closebtn {
    cursor: pointer;
  }
  div[id^=chatBody] > .card-body {
    overflow-y: auto;
  }
}

.chatmessage {
  @extend .alert;
  padding: 2px;
  .message {
    font-size: 12px;
  }
  .timestamp {
    font-size: 10px;
  }
  &.chat-own {
    @extend .ml-auto;
  }
  &.chat-person {
    @extend .mr-auto;
  }
}
